import React from 'react';
import { Row } from 'react-bootstrap';
import { FormTypes, sectionList } from '../../constants/queryFragments';
import loadable from '@loadable/component';
import { RightFloatContainer, GreyCardContentListCol } from '../../styles';

const ContentListColumn = ({ sectionData }) => {
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    setLoaded(true);
  }, []);
  return !loaded ? (
    <RightFloatContainer>
      {sectionData && (
        <>
          <Row>
            {sectionData?.list &&
              sectionData?.list?.map((section, index) => {
                if (
                  section?.type &&
                  sectionList?.includes(section?.type) &&
                  !FormTypes?.includes(section?.type)
                ) {
                  // const sectionData = sectionData.list[section.type];
                  const SectionComponent = loadable(() =>
                    import(`../../components/${section?.type}/${section?.type}`)
                  );
                  return (
                    <GreyCardContentListCol
                      xs={12}
                      key={index}
                      className="px-0"
                    >
                      <SectionComponent key={index} sectionData={section} />
                    </GreyCardContentListCol>
                  );
                }
                return <></>;
              })}
          </Row>
        </>
      )}
    </RightFloatContainer>
  ) : (
    <RightFloatContainer>
      {sectionData && (
        <>
          <Row>
            {sectionData?.list &&
              sectionData?.list?.map((section, index) => {
                if (
                  section?.type &&
                  sectionList?.includes(section?.type)
                ) {
                  // const sectionData = sectionData.list[section.type];
                  const SectionComponent = loadable(() =>
                    import(`../../components/${section?.type}/${section?.type}`)
                  );
                  return (
                    <GreyCardContentListCol
                      xs={12}
                      key={index}
                      className="px-0"
                    >
                      <SectionComponent key={index} sectionData={section} />
                    </GreyCardContentListCol>
                  );
                }
                return <></>;
              })}
          </Row>
        </>
      )}
    </RightFloatContainer>
  );
};
export default ContentListColumn;
